// components/ChangePasswordModal.js
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';

function ChangePasswordModal({ onClose }) {
  const [username, setEmailValue] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const handleChangePassword = async () => {
    try {
      if (!username || !oldPassword || !newPassword) {
        setStatusMessage('All fields are required.');
        return;
      }
      
      const response = await axios.post('https://kmma-gv-api-fqfae5a3bwfnb5a0.westeurope-01.azurewebsites.net/api/update-password', {
        username,
        oldPassword,
        newPassword,
      }, {
        headers: {
          'x-api-key': '1aa094ff-1f80-4b37-b1f6-7db57209ef6a'
        }
      });
      setStatusMessage(response.data.message);
    } catch (error) {
      setStatusMessage(error.response?.data?.error || 'An error occurred. Please try again.');
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <strong>Change Password</strong>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Email Address"
          type="text"
          fullWidth
          variant="outlined"
          value={username} onChange={(e) => setEmailValue(e.target.value)}
          placeholder="Enter your email address"
        />
        <TextField
          margin="dense"
          label="Old Password"
          type="password"
          fullWidth
          variant="outlined"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <TextField
          margin="dense"
          label="New Password"
          type="password"
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="error">
          Cancel
        </Button>
        <Button onClick={handleChangePassword} variant="contained" color="success">
          Submit
        </Button>
      </DialogActions>
      {statusMessage && <p style={{ marginLeft: '20px', color: 'red' }}>{statusMessage}</p>}
    </Dialog>
  );
}

export default ChangePasswordModal;

// ChangePasswordModal.css (Remove as we are now using Material UI components for styling)
