// Header.js
// Extracted Header Component
import React, { useState } from "react";
import axios from 'axios';
import './page.css';
import { handleLogout } from "../utils/Logout"; // Import the logout utility
import ChangePasswordModal from './ChangePasswordModal'; // Import the ChangePasswordModal component

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  const handleChangePasswordClick = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleModalClose = () => {
    setIsChangePasswordModalOpen(false);
  };

  return (
    <div className="top-nav">
      <div className="nav-container">
        <div className="top-nav-left">
          <div className="logo">
            <a>
              <img src="/km_white_logo.svg" alt="KM Malta Airlines Logo" />
            </a>
          </div>
          <div className="burger-div">
            <button
              className="burger-menu-button"
              onClick={() => setIsModalOpen(true)}
            >
              ☰
            </button>
          </div>
          {/* Modal for Mobile Navigation */}
          {isModalOpen && (
            <div className="burger-modal">
              <button
                className="close-modal-button"
                onClick={() => setIsModalOpen(false)}
              >
              </button>
              <div className="modal-content">
                <div className="mobile-urls">
                  <a className="nav-url" href="https://kmmaltairlines.com">
                    Back to the website
                  </a>
                  <hr
                    className="mobile-nav-separator"
                    width="100%"
                    color="black"
                    size="1px"
                    align="left"
                  ></hr>
                  <a
                    className="nav-url"
                    href="https://help.kmmaltairlines.com/s/"
                  >
                    Help
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="nav-links home-link">
            <a className="nav-url" href="https://kmmaltairlines.com">
              
            </a>
          </div>
        </div>
        <div className="nav-right">
          <div className="help-link">
            <a className="nav-url" onClick={handleChangePasswordClick}>
              <span>Change Password</span>
            </a>
            <span className="nav-delimiter"> | </span>
            <a className="nav-url" onClick={handleLogout}>
              <span>Logout</span>
            </a>
          </div>
        </div>
      </div>
      {isChangePasswordModalOpen && <ChangePasswordModal onClose={handleModalClose} />}
    </div>
  );
};

export default Header;