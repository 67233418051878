// Extracted Footer Component
import React from "react";
import "./page.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <div className="footer-column">
          <h4>KM Malta Airlines Ltd.</h4>
          <ul>
            <li>
              <a href="https://kmmaltairlines.com/en/">
                Main Website
              </a>
            </li>
            <li>
              <a href="https://kmmaltairlines.com/en/legal-and-policies">
                Legal and Policies
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Help & Support</h4>
          <ul>
            <li>
              <a href="mailto:servicedesk@kmmaltairlines.com">
                Gift Voucher Application Support
              </a>
            </li>
            <li>
              <a href="mailto:razvan.nemes@qh-c.com">
                Urgent Support Request
              </a>
            </li>
          </ul>
        </div>
        
        <div className="footer-apps">
          <div className="app-store">
            <a href="https://apps.apple.com/us/app/km-malta-airlines/id6499222465">
              <img src="/App-Store@2x.webp" alt="Download on the App Store" />
            </a>
          </div>
          <div className="google-play">
            <a href="https://play.google.com/store/apps/details?id=com.maltairlines">
              <img src="/Google-Play@2x.webp" alt="Get it on Google Play" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>2024 &copy; KM Malta Airlines Limited. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;