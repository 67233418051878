// src/components/login.js
import React, { useState } from "react";
import axios from "axios";
import "./Login.css"; // Import the CSS for styling the login page

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://kmma-gv-api-fqfae5a3bwfnb5a0.westeurope-01.azurewebsites.net/api/login", {
        username,
        password,
      }, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "1aa094ff-1f80-4b37-b1f6-7db57209ef6a",
        },
      });
      localStorage.setItem("token", response.data.token);
      setMessage("Login successful");
      window.location.href = "/";
    } catch (error) {
      setMessage("Error: " + (error.response?.data?.msg || "Login failed"));
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="/dark_logo.png" alt="KM Malta Airlines Logo" className="login-logo" />
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
          <p className="login-message">{message}</p>
        </form>
      </div>
    </div>
  );
};

export default Login;